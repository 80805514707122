import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactPage from "../components/ContactPage"

function Contact() {
    return (
        <>
            <Navbar />
            <ContactPage />
            <Footer />
        </>
    );
}

export default Contact;