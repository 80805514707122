import React from 'react';
import { Link } from 'react-router-dom';
import "./css/Services.css";

const ResearchPublications = () => {
    return (
        <div className='specificService'>
            <div className="container py-5">
                <h2 className="mb-4 display-6 fw-medium text-center">Research and Publications</h2>
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>Overview</h5>
                        <p style={{ fontSize: "1.1rem" }}>
                            Through Disability Surveys and Gender Analysis Evaluation Tool Kits, the Centre has formulated Gender and Disability Reports, Disability Accessibility Audits setting the benchmark for the implementation of Gender and Disability Mainstreaming Actions in Governments, State, Public and Private Entities in Kenya.
                        </p>
                    </div>

                    <div className="col-md-12 mb-4">
                        <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>Data Availability</h5>
                        <p style={{ fontSize: "1.1rem" }}>
                            The sex disaggregated data and Disability statistical indicators drawn from Gender and Disability Audits conducted by GDDC are eventually made public as quarterly reports available on our official website:
                            <a href="http://www.gddckenya.org" target="_blank" rel="noopener noreferrer" className="ms-3 me-3 text-decoration-underline"> www.gddckenya.org</a> 
                            or subscribe to our GDDC and GEDICON Newsletters via email:
                            <a href="mailto:info@gddckenya.org" className="text-decoration-underline"> info@gddckenya.org</a>.
                        </p>
                    </div>

                    <div className="col-md-12 text-center">
                        <Link to="/services" className="btn btn-secondary text-light">Back to Services</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResearchPublications;
