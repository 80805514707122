export default function EventsDescription() {
    return (
        <div className="container">
            <div className="mb-4">
                <h4>1. MDAs, State Corporations, and Parastatals July-October Training and Sensitization Workshops</h4>
                <p>
                    This 3-day training and sensitization programme is designed for gender mainstreaming committee members in close
                    collaboration with the State Department of Gender. It will be opened with a keynote address from Grace Wasike,
                    Director of Gender Directorate. The sensitizations will run across the country, reaching MDAs, universities,
                    and TVET institutions.
                </p>
            </div>

            <div className="mb-4">
                <h4>2. Gender and Disability Mainstreaming Congress for State Corporations Board & Council Directors and Members</h4>
                <p>
                    This workshop provides board and council members with the opportunity to learn and network with national best
                    performers who have implemented equality and inclusion indicators as cross-cutting targets in service delivery
                    performance. Keynote speakers and facilitators include the Principal Secretary of the State Department of Social
                    Protection, members of IACC State and Non-State Actors, Ms. Grace Wasike, Director Gender, Ministry of Public
                    Service and Gender Affairs, and the Head of Disability, Directorate of Social Development. The congress will be
                    coordinated by the Director of GDDC, Mr. Richard S. Nasong’o, a renowned expert on public sector equality and
                    inclusion and a member of IACC 2022-2025.
                </p>
            </div>

            <div className="mb-4">
                <h4>3. National Workshop on Accessibility and Usability Rights for Persons with Disabilities</h4>
                <p>
                    This workshop unites state and non-state actors, the private sector, and others to address issues that hinder
                    persons with disabilities from accessing public utilities and services on an equal basis with those without
                    disabilities.
                </p>
            </div>

            <div className="mb-4">
                <h4>4. Workshop on Inclusive Advertising for HR, Procurement Officers, and Corporate Functions</h4>
                <p>
                    This workshop provides training for key officers in public institutions who generate public-facing information,
                    including procurement, HR, and corporate relations personnel. The goal is to ensure that information is accessible
                    to all, regardless of disability, addressing the communication gaps that have historically left out persons with
                    hearing and visual impairments.
                </p>
            </div>

            <div className="mb-4">
                <h4>5. Alignment of the National Policy on Gender and Development to Institutional Policies</h4>
                <p>
                    The State Department for Gender provides guidance for MDAs to align the National Policy on Gender and Development
                    2019 with their institutional policies. GDDC will convene a workshop to guide institutions on how to align their
                    gender mainstreaming programs step by step with the National Sectorial Gender Mainstreaming provisions.
                </p>
            </div>

            <div className="mb-4">
                <h4>Payment Information</h4>
                <p>
                    Payment rates are inclusive of 16% VAT. Institutions can request individual workshop invitations, announcements,
                    and registration forms from:
                </p>

                <address>
                    <strong>Gender and Disability Development Centre</strong><br />
                    3rd Floor, Grey Park Heights, Eastern Bypass<br />
                    P.O Box 2221-00200 (City Square)<br />
                    Nairobi, Kenya<br />
                    Tel: 0207856896, 0722451844<br />
                    Email: <a href="mailto:info@gddckenya.org">info@gddckenya.org</a><br />
                    Website: <a href="http://www.gddckenya.org" target="_blank" rel="noopener noreferrer">www.gddckenya.org</a>
                </address>
            </div>
        </div>
    );
}
