import React, { useEffect, useState } from 'react';

const OurImpact = () => {
  const [satisfaction, setSatisfaction] = useState(0);
  const [experience, setExperience] = useState(0);
  const [projects, setProjects] = useState(0);
  const [employees, setEmployees] = useState(0);

  useEffect(() => {
    const incrementValues = (setValue, target, delay) => {
      let count = 0;
      const interval = setInterval(() => {
        if (count < target) {
          count++;
          setValue(count);
        } else {
          clearInterval(interval);
        }
      }, delay);
    };

    incrementValues(setSatisfaction, 98, 30);
    incrementValues(setExperience, 12, 150);
    incrementValues(setProjects, 120, 20);
    incrementValues(setEmployees, 80, 40);
  }, []);

  return (
    <div className="container-fluid bg-dark text-light py-5">
      <div className="container text-center">
        <h2 className="mb-5 display-6 fw-bold">Measuring Our Impact</h2>
        <div className="row">
          <div className="col-md-3">
            <h4>Client Satisfactory Rate</h4>
            <h1>{satisfaction}%</h1>
          </div>
          <div className="col-md-3">
            <h4>Years of Experience</h4>
            <h1>{experience}</h1>
          </div>
          <div className="col-md-3">
            <h4>Projects Completed</h4>
            <h1>{projects}</h1>
          </div>
          <div className="col-md-3">
            <h4>Happy Employees</h4>
            <h1>{employees}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurImpact;
