import React from "react";
import "./css/LoadingScreen.css";
import logo from "./images/GDDC LOGO.png"

const LoadingScreen = () => {
  return (
    <div className="loading-container">
      <img src={logo} alt="Logo" className="loading-logo" />
      <div className="loading-circles">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
