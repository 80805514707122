import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PrivacyPolicyContent from "../components/PrivacyPolicyContent"

function PrivacyPolicy() {
    return (
        <>
            <Navbar />
            <PrivacyPolicyContent />
            <Footer />
        </>
    );
}

export default PrivacyPolicy;