import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Content from "../components/404"

export default function About() {
    return (
        <>
            <Navbar />
            <Content />
            <Footer />
        </>
    );
}