import React from "react";
import "./css/Services.css";

const resources = [
  {
    name: "GDDC Calendar 2024-2025 Q1 Q2",
    file: "/resources/THE 2024-2025 GENDER AND DISABILITY MAINSTREAMING TRAINING AND SENSITIZATION CALENDAR..pdf",
    description: "Explore GDDC Kenya’s official event calendar for the latest training, workshops, and sensitization events.",
  },
  {
    name: "GDDC Souvenir Magazine",
    file: "/resources/GDDC Souvenir Magazine 2017 4 web.pdf",
    description: "Take a look at highlights from GDDC’s milestones and achievements in gender and disability inclusion.",
  },
  {
    name: "National Gender and Disability Mainstreaming Excellency Awards",
    file: "/resources/DISABILITY SUPPLEMENT TEMPLATE.pdf",
    description: "A comprehensive document on Kenya's 3rd National Gender and Disability Mainstreaming Excellency Awards, detailing efforts, achievements, and standards in disability inclusivity and gender equity. Highlights include policy benchmarks, sector champions, and organizational advancements toward an inclusive society."
  },
  {
    name: "Past Winners",
    file: "/resources/pastwinners.pdf",
    description: "View the list of past winners recognized for their exceptional contributions to gender and disability advancement.",
  },
  {
    name: "Diverse Kenya Media Invitation",
    file: "/resources/DiverseKenya 2012 Media invitation.docx",
    description: "An official media invitation showcasing Kenya’s commitment to diverse and inclusive representation.",
  },
  {
    name: "Disability Awareness Creation Booklet",
    file: "/resources/MoLSP-Disability-Awareness-Creation-Booklet-2021.pdf",
    description: "Guidelines and resources for creating awareness on disability inclusion and rights in workplaces.",
  },
  {
    name: "IACC Workplan",
    file: "/resources/IACC WORKPLAN.pdf",
    description: "National action plan for implementation of global disbility summit",
  },
];

function ResourcesContent() {
  return (
    <div className="specificService">
      <div className="container py-5">
        <h2 className="text-center mb-5">GDDC Kenya Resources</h2>
        <div className="row">
          {resources.map((resource, index) => (
            <div key={index} className="col-md-6 mb-4">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">{resource.name}</h5>
                  <p className="card-text">{resource.description}</p>
                  <a href={resource.file} download className="btn btn-secondary">
                    Download
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ResourcesContent;
