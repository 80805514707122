export default function WhyChooseUs() {
    return (
        <div className="container-fluid bg-dark text-light py-5">
            <div className="container">
                <div className="row align-items-center text-center text-lg-start" style={{ minHeight: '60vh' }}>
                    <div className="col-lg-6 mb-4">
                        <h2 className="display-6 fw-bold">
                            Why Choose GDDC?
                        </h2>
                        <p className="lead">
                            At GDDC, we lead the charge in promoting inclusive development by mainstreaming gender and disability policies.
                            Here's why we are the best partner in fostering social equity and inclusion.
                        </p>
                    </div>

                    <div className="col-lg-6 d-flex flex-column align-items-center align-items-lg-start">
                        <div className="mb-4 text-center text-lg-start">
                            <h4 className="fw-bold">Inclusive Policy Development</h4>
                            <p>
                                We work with governments, institutions, and stakeholders to develop policies that prioritize gender equality and disability rights, ensuring an inclusive society.
                            </p>
                        </div>
                        <hr className="d-lg-none" /> {/* Hide the hr on larger screens */}
                        <div className="mb-4 text-center text-lg-start">
                            <h4 className="fw-bold">Capacity Building Programs</h4>
                            <p>
                                GDDC has trained over 150,000 officers across various sectors, empowering them with skills and knowledge to champion gender and disability mainstreaming.
                            </p>
                        </div>
                        <hr className="d-lg-none" />
                        <div className="mb-4 text-center text-lg-start">
                            <h4 className="fw-bold">Community Engagement</h4>
                            <p>
                                We engage communities through workshops and outreach programs, driving awareness and encouraging grassroots participation in our inclusive development initiatives.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
