import "./css/PartnersClients.css";
import antiFGM from "./images/Anti FGM Board.jpg"
import ACFC from "./images/ACFC.png"
import assetsRecovery from "./images/Assets Recovery Agency.png"
import bumbeTTI from "./images/Bumbe TTI.png"
import cak from "./images/CAK.jpg"
import CoC from "./images/CoC.jpg"
import CUE from "./images/CUE.png"
import CWWDA from "./images/CWWDA.png"
import EAPCC from "./images/EAPCC.png"
import Garissa from "./images/Garissa.jpg"
import GDC from "./images/GDC.webp"
import ICYA from "./images/ICYA.png"
import IEBC from "./images/IEBC.jpg"
import Jooust from "./images/JOOUST.png"
import KAA from "./images/KAA.jpg"
import Kabarak from "./images/Kabarak.png"
import KAS from "./images/KAS.jpg"
import KCAUni from "./images/KCA University.png"
import KCAA from "./images/KCAA.jpg"
import KCB from "./images/KCB.png"
import KDC from "./images/KDC.jpg"
import KEMFRI from "./images/KEMFRI.png"
import KEMRI from "./images/KEMRI.png"
import KENGEN from "./images/KENGEN LOGO.png"
import KENHA from "./images/KENHA LOGO.jpg"
import Kenyare from "./images/Kenya RE.png"
import KEPHIS from "./images/KEPHIS.jpg"
import KEVEVAPI from "./images/KEVEVAPI.jpg"
import KIBU from "./images/KIBU.png"
import KIHBT from "./images/KIHBT.jpg"
import KISE from "./images/KISE.jpg"
import KisiiPoly from "./images/Kisii National Polytechnic.jpg"
import KLRC from "./images/KLRC.png"
import KNH from "./images/KNH.jpg"
import Koitalel from "./images/Koitalel Samoei.jpg"
import KPA from "./images/KPA.jpg"
import KRC from "./images/KRC.jpg"
import KSA from "./images/KSA.jpg"
import KU from "./images/KU.jpg"
import KUTRH from "./images/KUTRH.jpg"
import KWS from "./images/KWS.png"
import Laikipia from "./images/LAIKIPIA.jpg"
import LAPFUND from "./images/LAPFUND.png"
import Maasai from "./images/Maasai Mara University.jpg"
import Makueni from "./images/Makueni County.png"
import MMU from "./images/Multimedia University.jpg"
import NACOSTI from "./images/NACOSTI.jpg"
import NairobiCounty from "./images/Nairobi County.png"
import NationalIrrigation from "./images/National Irrigation.png"
import NCA from "./images/NCA.png"
import NCK from "./images/NCK.jpg"
import NEPAD from "./images/NEPAD KENYA.png"
import NITA from "./images/NITA-Logo.png"
import NSDCC from "./images/NSDCC.jpg"
import NTSA from "./images/NTSA.svg"
import Pwani from "./images/Pwani.png"
import RBA from "./images/RBA.png"
import Rongo from "./images/Rongo University.png"
import SHA from "./images/SHA.svg"
import SKC from "./images/SKC.png"
import TSC from "./images/TSC LOGO.jpg"
import UFAA from "./images/UFAA.jpg"
import Ugunja from "./images/Ugunja TTI.jpg"
import UoE from "./images/UoE.jpg"
import UoN from "./images/UoN_Logo.png"
import USIU from "./images/USIU.png"
import Utalii from "./images/Utalii College.jpg"
import WEF from "./images/WEF.jpg"
import WorldVision from "./images/World vision.png"
import Wote from "./images/WOTE TTI.jpg"
import WRTI from "./images/WRTI.png"
import WSTF from "./images/WSTF LOGO BANNER.png"
import Zetech from "./images/Zetech.jpg"

const logos = [
    TSC,
    UFAA,
    UoE,
    UoN,
    USIU,
    NairobiCounty,
    WEF,
    NEPAD,
    WorldVision,
    Zetech,
    Utalii,
    Pwani,
    NITA,
    SHA,
    WRTI,
    SKC,
    Rongo,
    WSTF,
    RBA,
    NSDCC,
    NTSA,
    NACOSTI,
    NationalIrrigation,
    antiFGM,
    Makueni,
    Laikipia,
    LAPFUND,
    NCA,
    NCK,
    Maasai,
    MMU,
    KIBU,
    KNH,
    KIHBT,
    KWS,
    ACFC,
    KU,
    KUTRH,
    KEVEVAPI,
    KISE,
    KisiiPoly,
    KRC,
    KSA,
    KPA,
    Wote,
    KLRC,
    assetsRecovery,
    Koitalel,
    bumbeTTI,
    cak,
    CoC,
    CUE,
    KDC,
    CWWDA,
    KEPHIS,
    EAPCC,
    Garissa,
    KEMFRI,
    KEMRI,
    KENGEN,
    Kenyare,
    Ugunja,
    KENHA,
    GDC,
    ICYA,
    IEBC,
    Jooust,
    KCB,
    KAA,
    Kabarak,
    KAS,
    KCAUni,
    KCAA
];

export default function AwardNominees() {
  return (
    <div className="award-nominees-section py-5">
      <h5 className="mb-5 display-6 fw-medium text-center text-secondary">Award Nominees</h5>
      <div className="scrolling-logos">
        <div className="logo-track">
          {logos.map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt={`Nominee Logo ${index + 1}`}
              className="logo"
            />
          ))}
          {logos.map((logo, index) => (
            <img
              key={index + logos.length}
              src={logo}
              alt={`Nominee Logo`}
              className="logo"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
