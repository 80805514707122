import Navbar from "../components/Navbar";
import OurImpact from "../components/OurImpact";
import CoreValues from "../components/CoreValues";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import TrainingContent from "../components/TrainingWorkshop"

function TrainingWorkshop() {
    return (
        <>
            <Navbar />
            <TrainingContent />
            <OurImpact />
            <CoreValues />
            <CallToAction />
            <Footer />
        </>
    );
}

export default TrainingWorkshop;