import "./css/style.css";

const AwardsSelectionCriterion = () => {
  return (
    <div className="container">
      <h3 className="text-center mb-4 text-secondary">
        Awards Selection Criterion
      </h3>
      <div className="row">
        <div className="col-md-6 mb-4">
          <h5 className="text-secondary">Inclusive Health Awards</h5>
          <ul className="list-unstyled">
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Private Hospitals: Accessibility, Customer Support, Etiquette,
              Inclusion Policy
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              The Most Inclusive Private Hospitals:
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Government-Owned Hospitals (Devolved): Accessibility, Inclusive
              Service Provision, Etiquette
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Most Inclusive Level Category Hospital: (KNH, MTRH, Level 5, 4, 3
              Hospitals)
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Referral Hospitals: Accessibility, Inclusive Service Provision,
              Etiquette
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Medical Training Institutions: Accessibility, Inclusive Service
              Provision, Etiquette, Curriculum, and Pedagogy
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Mental Health Service Provision: Facilities and Inclusive Manpower
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              The Most Inclusive Rehabilitation Centre:
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              County Inclusive Health Policy and Programs: Recognizing the Most
              Inclusive Counties in Kenya (Winners, 1st and 2nd Runners-up)
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
          </ul>
        </div>

        <div className="col-md-6 mb-4">
          <h5 className="text-secondary">Inclusive Education Awards</h5>
          <ul className="list-unstyled">
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Basic Education - The Most Inclusive Learning Institution:
              Accessibility, SNE Curriculum Implementation, Teachers Pedagogy,
              KNEC Results
              <br />
              <em>Key Awards Informants:</em> MoE, KNEC, KISE, GDDC, Independent
              Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Tertiary - The Most Inclusive TVET Institution: Accessibility,
              Enrolment, Tutor Pedagogy, Etiquette, Levels/Culture, KNEC
              Results, Employability Output Levels
              <br />
              <em>Key Awards Informants:</em> MoE, KATTI, KNEC, KISE, GDDC,
              Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              University - Most Inclusive University in Kenya: Accessibility,
              Admissions, Lecturers Pedagogy, Etiquette, Levels/Culture,
              Employability Output Levels
              <br />
              <em>Key Awards Informants:</em> MoE, CUE, KNEC, KISE, GDDC,
              Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Professional Bodies and Associations: Number of Members with
              Disabilities, Inclusive Membership Policy, Demonstrated
              Mainstreaming Programs
              <br />
              <em>Key Awards Informants:</em> MoE, KNQA, KENAS, MOLSP, GDDC,
              Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              County Education Stimuli - Most Inclusive County in Provision of
              Inclusive Education: Robust Affirmative Action Policy, County
              Disability Policy, Actualized Implementation Plans
              <br />
              <em>Key Awards Informants:</em> MoE, CoG, KNQA, KENAS, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Most Voted Education Assessment Resource Centre: Peer
              Recommendation for Excellence in Inclusive Education Resources
              <br />
              <em>Key Awards Informants:</em> MoE, CoG, GDDC, Independent Expert
            </li>
          </ul>
        </div>

        <div className="col-md-6 mb-4">
          <h5 className="text-secondary">Legal Inclusiveness Awards</h5>
          <ul className="list-unstyled">
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Young Professionals: Recognizes inclusive legal
              services provided by young professionals in law firms, legal
              support, and pro-bono services.
              <br />
              <em>Key Awards Informants:</em> LSK, JSC, NGEC, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
                The Most Inclusive High Courts and Resident Magistrate Courts:{" "}
              Acknowledges inclusivity efforts in high courts across Kenya.
              <br />
              <em>Key Awards Informants:</em> JSC, NCPWD, GDDC
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Non-Profit/Government: Recognizes inclusivity in
              INGOs and NGOs that contribute to legal support and empowerment.
              <br />
              <em>Key Awards Informants:</em> GDDC
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Law Firms: Highlights inclusive legal practices
              and public engagement by law firms.
              <br />
              <em>Key Awards Informants:</em> LSK, GDDC, Public, MEDIA
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Individuals: Celebrates individual contributions
              to public legal inclusiveness.
              <br />
              <em>Key Awards Informants:</em> LSK, GDDC, Public, MEDIA
            </li>
          </ul>
        </div>

        <div className="col-md-6 mb-4">
          <h5 className="text-secondary">
            Inclusive Data, Technology, and Innovation Awards
          </h5>
          <ul className="list-unstyled">
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Most Accessible Websites and Portals:
              <br />
              <em>Key Awards Informants:</em> The Public, GDDC, ICTA, In-Able,
              KEBS, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Inclusive ICT Solution:
              <br />
              <em>Key Awards Informants:</em> The Public, GDDC, ICTA, In-Able,
              KEBS, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Inclusion Innovation of the Year:
              <br />
              <em>Key Awards Informants:</em> The Public, GDDC, ICTA, In-Able,
              KEBS, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Inclusion Technology Champion of the Year:
              <br />
              <em>Key Awards Informants:</em> GDDC, The Public, ICTA, In-Able,
              KEBS, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Winner (Non-State):
              <br />
              <em>Key Awards Informants:</em> GDDC, The Public, ICTA, In-Able,
              KEBS, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Winner (GoK):
              <br />
              <em>Key Awards Informants:</em> ICTA, In-Able, GDDC, KEBS, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Overall Winner:
              <br />
              <em>Key Awards Informants:</em> ICTA, In-Able, GDDC, KEBS, NCPWD
            </li>
          </ul>
        </div>

        <div className="col-md-6 mb-4">
          <h5 className="text-secondary">Economic Empowerment Awards</h5>
          <ul className="list-unstyled">
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Most Inclusive Micro and Small Enterprise:
              <br />
              <em>Key Awards Informants:</em> GDDC, Ministry of Cooperatives and
              MSMEs, MSEA, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Micro-Entrepreneur with Disability:
              <br />
              <em>Key Awards Informants:</em> GDDC, Ministry of Cooperatives and
              MSMEs, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Micro-Entrepreneur with Disability (AGPO):
              <br />
              <em>Key Awards Informants:</em> GDDC, Ministry of Cooperatives and
              MSMEs, MSEA, NCPWD
            </li>
          </ul>
        </div>

        <div className="col-md-6 mb-4">
          <h5 className="text-secondary">
            Climate Change and Disability Inclusion Awards
          </h5>
          <ul className="list-unstyled">
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              Inclusive Climate Interventions: Recognizing
              counties with inclusive climate policies, <br />
              <em>Key Awards Informants:</em> Kenya Forest Service(KFS), Kenya
              Forest Research Institute(KEFRI), Kenya Water Towers Agency,
              NETFUND, NECC, National Environmental Tribunal, GDDC.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AwardsSelectionCriterion;
