import React from 'react';
import { Link } from 'react-router-dom';
import "./css/Services.css";

const Certification = () => {
    return (
        <div className='specificService'>
            <div className="container py-5">
                <h2 className="mb-4 display-6 fw-medium text-center">Certification Programs</h2>
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>Overview</h5>
                        <p style={{ fontSize: "1.1rem" }}>
                        GDDC has accredited corporates/organizations and Gender and Disability professionals as accredited members. All accredited members receive discounted training certification programs for their Policy Developers, Board Representatives, Chief Executive Officers, Managing Directors, Departmental Heads/Supervisors, Gender and Disability Officers, Human Resource Development, and Education institutions are now required to demonstrate Gender Awareness and Disability Accessibility best practices in the workplace. This is achieved through setting Gender Mainstreaming targets, policy reform, and budgetary allocations towards Gender and Disability Mainstreaming under MDA’s.
                        </p>
                    </div>

                    <div className="col-md-12 mb-4">
                        <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>Certification Programs</h5>
                        <p style={{ fontSize: "1.1rem" }}>
                            GDDC offers tailored certification programs designed to meet the demands of numerous Gender and Disability Mainstreaming functions in government and affiliate agencies, as mandated by Performance Contracting under the purview of the Social Services and Governance Division.
                        </p>
                        <p style={{ fontSize: "1.1rem" }}>
                            To ensure professional success, it is essential to continuously expand your skills and education. GDDC is dedicated to championing Gender and Disability Mainstreaming as an educational and professional certification that equips institutions and their employees with the necessary skills and tools to become leading Gender and Disability Development Awareness institutions in Africa.
                        </p>
                        <p style={{ fontSize: "1.1rem" }}>
                            With the GDDC Certified Gender and Disability Mainstreaming Program Certificate, participants and their organizations can apply the acquired knowledge to:
                        </p>
                        <ul style={{ fontSize: "1.1rem" }}>
                            <li>Monitor and report on Gender and Disability Mainstreaming efforts.</li>
                            <li>Improve internal quality standards within the institution.</li>
                            <li>Implement effective Gender and Disability policies that align with best practices.</li>
                        </ul>
                    </div>

                    <div className="col-md-12 text-center">
                        <Link to="/services" className="btn btn-secondary">Back to Services</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Certification;
