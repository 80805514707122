import Navbar from "../components/Navbar";
import OurImpact from "../components/OurImpact";
import CoreValues from "../components/CoreValues";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import  CertificationContent from "../components/Certification"

function Certification() {
    return (
        <>
            <Navbar />
            <CertificationContent />
            <OurImpact />
            <CoreValues />
            <CallToAction />
            <Footer />
        </>
    );
}

export default Certification;