import "./css/ProfileSummary.css";

export default function ProfileSummary() {
    return (
        <div className="profile-summary">
            <div className="container text-center text-light">
                <h2 className="display-6 fw-bold pt-5 mb-3">About GDDC Kenya</h2>
                <p className="lead text-justify">
                    The Gender and Disability Development Centre is the leading Kenyan Chapter Organization that assists Public and Private Institutions as well as institutions of Higher Education in research, baseline surveys, and the implementation of Gender and Disability Mainstreaming best practices and tenets in Africa.
                </p>
                <p className="lead text-justify">
                    The centre has addressed gender mainstreaming in public disability policy matters in Kenya through its quarterly and bi-annual training programmes that have trained over 150,000 Officers from Kenya Government MDA’s on Mainstreaming Gender and Disability.
                </p>
                <p className="lead text-justify">
                    We pride in having the best human and technical resources versed in international best practice for conducting Baseline Surveys for both Gender and Disability Mainstreaming, Gender Mainstreaming in Disability Policies, Disability Audit, Gender and Disability Mainstreaming of development projects, Implementation of Gender and Disability Mainstreaming tool kits in the workforce, Gender Analysis of Development planning, Constitutional Reform, Electoral and Governance Process in the region.
                </p>
            </div>
        </div>
    );
}
