import Navbar from "../components/Navbar";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import AwardsContent from "../components/AwardsContent";

function Awards() {
    return (
        <>
            <Navbar />
            <AwardsContent />
            <CallToAction />
            <Footer />
        </>
    );
}

export default Awards;