import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import TermsConditionsContent from "../components/TermsConditionsContent"

function TermsConditions() {
    return (
        <>
            <Navbar />
            <TermsConditionsContent />
            <Footer />
        </>
    );
}

export default TermsConditions;