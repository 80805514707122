import React from 'react';
import { Link } from 'react-router-dom';
import "./css/Services.css";

const Consultancy = () => {
    return (
        <div className='specificService'>
            <div className="container py-5">
                <h2 className="mb-4 display-6 fw-medium text-center">Consultancy Services</h2>
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>Overview</h5>
                        <p style={{ fontSize: "1.1rem" }}>
                            GDDC offers comprehensive consultancy services focused on Gender and Disability Mainstreaming for both State/Government and private institutions. Our team conducts baseline surveys, situational analyses, and policy planning actions to facilitate radical transformations in gender and disability practices within organizations.
                        </p>
                    </div>

                    <div className="col-md-12 mb-4">
                        <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>Areas of Expertise</h5>
                        <ul style={{ fontSize: "1.1rem" }}>
                            <li>Accessibility Audit</li>
                            <li>Disability Representation in Leadership</li>
                            <li>Employee retention of Persons with Disability (PWDs)</li>
                            <li>Job retention for persons who have acquired disability in the line of duty</li>
                            <li>Disability Mainstreaming Legal Framework</li>
                            <li>Recruitment and Promotion</li>
                            <li>Orientation and Induction of PWDs</li>
                            <li>Analysis of the different kinds of Disability and how to mainstream the work environment to suit the abilities of PWDs.</li>
                        </ul>
                    </div>

                    <div className="col-md-12 mb-4">
                        <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>Consulting Approach</h5>
                        <p style={{ fontSize: "1.1rem" }}>
                            GDDC's consultancy services adopt a threefold approach to enforce Gender and Disability Mainstreaming:
                        </p>
                        <ul style={{ fontSize: "1.1rem" }}>
                            <li>
                                <strong>GBV and Sexual Harassment Assessment Consultancy:</strong> To achieve this, GDDC provides services geared towards advocacy and sensitization of Gender equality and women empowerment, aligned with frameworks like CEDAW and UNSCR packages. Our approach focuses on Gender analysis and data collection for the implementation of Sexual Harassment and GBV policies.
                                <ul>
                                    <li>The Sexual Offences Act (SOA) 2006</li>
                                    <li>The Employment Act 2007</li>
                                    <li>The National Framework on Gender-Based Violence (2010)</li>
                                    <li>The Counter Trafficking in Persons Act 2007</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Strategic Planning:</strong> We use a systematic Gender Analysis and Disability Mainstreaming Tool Kit to aid organizations in identifying GBV in the workplace, assessing indicators like Direct Discrimination, Indirect Discrimination, and Sexual Harassment.
                            </li>
                            <li>
                                <strong>Performance Measurement:</strong> GDDC collects data to assess the effectiveness of policies, guiding organizations to identify areas for improvement in addressing sexual harassment and GBV.
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-12 mb-4">
                        <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>Performance Evaluation and Reporting</h5>
                        <p style={{ fontSize: "1.1rem" }}>
                            GDDC conducts baseline surveys and consultancy to provide institutions with actionable recommendations for reducing Sexual Offences and instituting policy reforms in Gender and Disability Mainstreaming.
                        </p>
                    </div>

                    <div className="col-md-12 mb-4">
                        <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>Gender Mainstreaming Policy Consultancy</h5>
                        <p style={{ fontSize: "1.1rem" }}>
                            GDDC aids government and private institutions in creating and adopting policies that promote Gender Equity and Equality. Our service includes drafting and guiding policies under frameworks like:
                        </p>
                        <ul style={{ fontSize: "1.1rem" }}>
                            <li>The Constitution of Kenya</li>
                            <li>The Employment Act</li>
                            <li>The Sexual Offences Act</li>
                            <li>NGEC Guidelines</li>
                        </ul>
                    </div>

                    <div className="col-md-12 mb-4">
                        <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>Disability Mainstreaming Policy</h5>
                        <p style={{ fontSize: "1.1rem" }}>
                            GDDC assists institutions in forming Disability Mainstreaming policies aligned with the Public Service Commission of Kenya’s guidelines. Our service includes accessibility audits and strategies for empowering PWDs through priority employment, inclusive HR policies, and CSR initiatives.
                        </p>
                    </div>

                    <div className="col-md-12 text-center">
                        <Link to="/services" className="btn btn-secondary">Back to Services</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Consultancy;
