import React from "react";

const Footer = () => {
  return (
    <footer className="bg-light py-5">
      <div className="container">
        <div className="row text-start">
          {/* About Us Section */}
          <div className="col-lg-3 mb-4">
            <h5 className="text-dark fw-bold">About Us</h5>
            <p className="text-muted">
              GDDC Kenya is dedicated to driving gender and disability
              mainstreaming across Kenya. We focus on building inclusive
              policies and empowering communities through education and
              advocacy.
            </p>
          </div>

          {/* Quick Links Section */}
          <div className="col-lg-3 mb-4">
            <h5 className="text-dark fw-bold">Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <a
                  href="/"
                  name="Home"
                  className="text-muted text-decoration-none"
                >
                  <i className="fas fa-angle-right me-2"></i>Home
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  name="About"
                  className="text-muted text-decoration-none"
                >
                  <i className="fas fa-angle-right me-2"></i>About
                </a>
              </li>
              <li>
                <a
                  href="/services"
                  name="Services"
                  className="text-muted text-decoration-none"
                >
                  <i className="fas fa-angle-right me-2"></i>Services
                </a>
              </li>
              <li>
                <a
                  href="/events"
                  name="Events"
                  className="text-muted text-decoration-none"
                >
                  <i className="fas fa-angle-right me-2"></i>Events
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  name="Contact"
                  className="text-muted text-decoration-none"
                >
                  <i className="fas fa-angle-right me-2"></i>Contact
                </a>
              </li>
              <li>
                <a
                  href="/terms-and-conditions"
                  name="Terms and Conditions"
                  className="text-muted text-decoration-none"
                >
                  <i className="fas fa-angle-right me-2"></i>Terms and
                  Conditions
                </a>
              </li>
              <li>
                <a
                  href="/privacy-policy"
                  name="Privacy Policy"
                  className="text-muted text-decoration-none"
                >
                  <i className="fas fa-angle-right me-2"></i>Privacy Policy
                </a>
              </li>
            </ul>
          </div>

          {/* Offerings Section */}
          <div className="col-lg-3 mb-4">
            <h5 className="text-dark fw-bold">Offerings</h5>
            <ul className="list-unstyled">
              <li>
                <a
                  href="/services/training-and-workshops"
                  name="In-House Training"
                  className="text-muted text-decoration-none"
                >
                  <i className="fas fa-angle-right me-2"></i>In-House Training
                  and Facilitation
                </a>
              </li>
              <li>
                <a
                  href="/services/policy-development"
                  name="Policy Development"
                  className="text-muted text-decoration-none"
                >
                  <i className="fas fa-angle-right me-2"></i>Policy Development
                </a>
              </li>
              <li>
                <a
                  href="/services/certification"
                  name="Certification"
                  className="text-muted text-decoration-none"
                >
                  <i className="fas fa-angle-right me-2"></i>Certification
                </a>
              </li>
              <li>
                <a
                  href="/services/training-and-workshops"
                  name="GEDICON Workshops"
                  className="text-muted text-decoration-none"
                >
                  <i className="fas fa-angle-right me-2"></i>GEDICON
                  conferences, seminars and workshops
                </a>
              </li>
              <li>
                <a
                  href="/services/research-and-publications"
                  name="Research and Publications"
                  className="text-muted text-decoration-none"
                >
                  <i className="fas fa-angle-right me-2"></i>Research and
                  Publication
                </a>
              </li>
              <li>
                <a
                  href="/national-equality-and-inclusion-awards"
                  name="Equality and Inclusion Awards"
                  className="text-muted text-decoration-none"
                >
                  <i className="fas fa-angle-right me-2"></i>The 2024 National
                  Equality and Inclusion Awards
                </a>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 mb-4">
            <h5 className="text-dark fw-bold">Contact Info</h5>
            <p className="text-muted mb-1">
              <i className="fas fa-map-marker-alt me-2"></i> P.O Box 2221-00200,
              Nairobi
            </p>
            <p className="text-muted mb-1">
              <i className="fas fa-phone me-2"></i> 0207856896/0722451844
            </p>
            <p className="text-muted">
              <i className="fas fa-envelope me-2"></i> info@gddckenya.org
            </p>
            <div className="d-flex mt-2 justify-content-start">
              <a
                href="https://www.facebook.com/p/Gender-and-Disability-Development-Centre-100067024896772/"
                name="Facebook"
                target="_blank"
                rel="noopener noreferrer"
                className="me-3 text-dark"
                style={{
                  borderRadius: "50%",
                  padding: "10px",
                  border: "1px solid #ccc",
                }}
              >
                <i className="fab fa-facebook-f fa-lg"></i>
              </a>
              <a
                href="https://x.com/gddckenya?lang=en"
                name="Twitter"
                target="_blank"
                rel="noopener noreferrer"
                className="me-3 text-dark"
                style={{
                  borderRadius: "50%",
                  padding: "10px",
                  border: "1px solid #ccc",
                }}
              >
                <i className="fab fa-twitter fa-lg"></i>
              </a>
              <a
                href="https://whatsapp.com/channel/0029VanEt3xGzzKU5MAd8s1s"
                name="WhatsApp"
                target="_blank"
                rel="noopener noreferrer"
                className="text-dark"
                style={{
                  borderRadius: "50%",
                  padding: "10px",
                  border: "1px solid #ccc",
                }}
              >
                <i className="fab fa-whatsapp fa-lg"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="text-center mt-4">
          <small className="text-muted">
            &copy; 2024 Gender Disability and Development Centre. All rights
            reserved. Designed by
            <a
              href="https://www.linkedin.com/in/cedric-olunga"
              name="LinkedIn Profile"
              className="ms-2 text-muted"
              target="_blank"
              rel="noopener noreferrer"
            >
              Olunga Tech Creations
            </a>
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
