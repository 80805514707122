import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./css/Services.css";
import conference1 from "./images/PHOTO-2024-02-23-14-31-27.jpg";
import conference2 from "./images/Senior Staff Members represented GDDC at the County Peer Review Mechanism Steering Committee and Stakeholders workshop at Sewela.jpg";
import conference3 from "./images/training.png";
import conference4 from "./images/Gender Mombasa038.jpg";
import conference5 from "./images/img017.png";
import conference6 from "./images/img018.png";
import conference7 from "./images/img020.png";
import conference8 from "./images/img021.png";
import conference9 from "./images/img023.png";
import conference10 from "./images/img024.png";

const TrainingWorkshop = () => {
  const conferenceImages = [
    { src: conference1, alt: "Conference 1" },
    { src: conference2, alt: "Conference 2" },
    { src: conference3, alt: "Conference 3" },
    { src: conference4, alt: "Conference 4" },
    { src: conference5, alt: "Conference 5" },
    { src: conference6, alt: "Conference 6" },
    { src: conference7, alt: "Conference 7" },
    { src: conference8, alt: "Conference 8" },
    { src: conference9, alt: "Conference 9" },
    { src: conference10, alt: "Conference 10" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === conferenceImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? conferenceImages.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="specificService">
      <div className="container py-5">
        <h2 className="mb-4 display-6 fw-medium text-center">
          Training and Workshops
        </h2>
        <div className="row">
          <div className="col-md-12 mb-4">
            <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>
              Overview
            </h5>
            <p style={{ fontSize: "1.1rem" }}>
              GDDC organizes quarterly and bi-annual in-house training workshops
              and seminars as a joint venture training initiative with MDAs,
              counties, universities, TVET institutions, INGOs, local NGOs,
              CBOs, Civil Society Institutions and the Private sector targeting
              employees and staff members of organizations, public and private
              institutions that endeavor to achieve policy reform and implement
              Gender and Disability Actions in the work environment.
            </p>
            <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>
              In-House Training and Facilitation
            </h5>
            <p style={{ fontSize: "1.1rem" }}>
              One of the more popular vehicles for accessing the centre’s
              educational offerings in regard to Gender and Disability
              Mainstreaming institutional planning is through our on-site
              trainings and management facilitations. Bringing an on-site
              training workshop in the workplace allows for participatory
              assessment of the Institution achievements and challenges in
              Gender and Mainstreaming. GDDC thus uses the information and data
              collected in customizing a training module that addresses your
              exact challenges in Gender and Disability Mainstreaming.
            </p>
            <p style={{ fontSize: "1.1rem" }}>
              This sensitization approach provides a more interactive,
              capacity-building, and personal learning experience for employees.
              Whether for departmental training or an organization-wide
              initiative, the advanced learning methodologies employed by GDDC
              create an intimate training atmosphere that maximizes knowledge
              transfer to enhance Gender and Disability Awareness.
            </p>
          </div>

          <div className="col-md-12 mb-4">
            <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>
              Customization and Methodology
            </h5>
            <p style={{ fontSize: "1.1rem" }}>
              We understand that Gender and Disability Mainstreaming Actions are
              sensitive and cannot be addressed by an “off-the-shelf solution.”
              GDDC subject matter experts work with your team as an education
              communication strategy focused on addressing specific
              institutional challenges in Gender and Disability Mainstreaming.
            </p>
            <p style={{ fontSize: "1.1rem" }}>
              By identifying real-life examples, GDDC creates lasting training
              experiences through customized scenarios that resonate with
              participants, maximizing both impact and return on investment.
              GDDC has the capacity to deliver training courses to groups of up
              to 300 participants per day.
            </p>
          </div>

          <div className="col-md-12 mb-4">
            <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>
              GEDICON Conferences, Seminars, and Workshops
            </h5>
            <p style={{ fontSize: "1.1rem" }}>
              GDDC organizes various Gender Mainstreaming Conferences (GEDICON)
              and seminars to mainstream Gender and Disability within government
              ministries, state corporations, and private sector employers.
              These workshops address mandatory policies, regulatory frameworks,
              and international treaties, guiding organizations on developing
              internal structures for mainstreaming.
            </p>
            <ul style={{ fontSize: "1.1rem" }}>
              <li>Gender and Disability Monitoring and Reporting Framework</li>
              <li>Gender and Disability Mainstreaming Budget Development</li>
              <li>Disability Accessibility Audits</li>
              <li>Gender and Disability Policy Development</li>
              <li>Leadership and Governance Audits</li>
              <li>Gender Mainstreaming in Disability Policies</li>
              <li>Research, Situational Analysis, and Recommendations</li>
              <li>Gender Analysis of County Funds and Development Projects</li>
            </ul>
            <p style={{ fontSize: "1.1rem" }}>
              These conferences bring together experts and scholars to equip
              stakeholders with best practices, regulatory standards, and
              policies aimed at eliminating gender- and disability-based
              discrimination within the workplace.
            </p>
          </div>

          <div className="col-md-12 my-4">
            <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>
              Past Conferences
            </h5>
            <p style={{ fontSize: "1.1rem" }}>
              Here are some highlights from our previous conferences and
              workshops.
            </p>
            <div className="carousel slide">
              <div style={{ width: "100%", height: "450px" }}>
                <img
                  src={conferenceImages[currentIndex].src}
                  alt={conferenceImages[currentIndex].alt}
                  className="d-block w-100 h-100"
                  style={{
                    objectFit: "cover",
                    objectPosition: "top",
                    borderRadius: "15px",
                  }}
                />
              </div>
              <div className="text-center mt-3">
                <button
                  onClick={handlePrevious}
                  className="btn btn-secondary mx-2"
                >
                  Previous
                </button>
                <button onClick={handleNext} className="btn btn-secondary mx-2">
                  Next
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-12 text-center">
            <Link to="/services" className="btn btn-secondary">
              Back to Services
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingWorkshop;
