import React from 'react';

export default function VisionMandate() {
  return (
    <div className="container my-5 text-center text-lg-start">
      <div className="row">
        {/* Our Vision Section */}
        <div className="col-lg-6">
          <h3 className="fw-bold">Our Vision</h3>
          <p className="lead">
            To advocate for equality and non-discrimination of Persons with Disability, Women, and Children through sensitization on Gender and Disability Mainstreaming as well as Policy reform actions in Kenya.
          </p>

          <div className="mt-4">
            <h4 className="fw-bold">Our Centres of Excellence</h4>
            <p className="lead">
              Most of GDDC research and educational services are around Training workshops, Research and Publications, Certification, Baseline Surveys/Studies and Consultancy services in the following key areas;
            </p>
            <ul className="lead custom-list list-unstyled">
              <li><i className="fas fa-check-circle me-2"></i>GBV and Sexual Harassment Assessment</li>
              <li><i className="fas fa-check-circle me-2"></i>Gender Mainstreaming Policy Development</li>
              <li><i className="fas fa-check-circle me-2"></i>Disability Mainstreaming Policy Development</li>
            </ul>

            <p className='lead'>
              Our Centers of Excellence focus on Gender and Disability Audit, Gender and Disability Leadership and Governance Audit, Gender and Disability Reporting Frameworks to Board and respective State agency as well as Monitoring units, the advocacy, sensitization and capacity building on emerging issues in Gender and disability Policy reforms, Research and Situational Analysis, Gender analysis of both public and private institutions.
            </p>
          </div>
        </div>

        <div className="col-lg-6">
          <h3 className="fw-bold">Our Mandate</h3>
          <p className="lead">
            The mandate of GDDC is to translate as well as implement both international and local constitutional imperatives, policy pronouncements and legislation on Gender and Disability mainstreaming into measurable and meaningful programs in Africa.
          </p>
          <p className="lead">
            Furthermore, by virtue of the gender perspective and drawing on the rights and freedoms set out in the International Convention on the Rights of Persons with Disabilities, the Millennium Development Goals, the Kenyan Constitution and Vision 2030, the overall mandate and the core business of GDDC is to provide high quality, professional expertise and support to Governments, Public and Private Institutions, Higher Education Institutions, States, Non-Governmental Organizations and Development Agencies. GDDC seeks to facilitate the execution of their executive responsibilities pertaining to Gender and Disability Mainstreaming, advocating for the rights of girls and children with disability, gender equality and the gender and disability mainstreaming of higher Education in Africa.
          </p>
          <p className="lead">
            This is done by integrating best practices in Gender mainstreaming of disability policies and Gender, Disability and Children Development to local governance and leadership in both private and public sectors.
          </p>
        </div>
      </div>
    </div>
  );
}
