import React from "react";
import "./css/Services.css";

export default function TermsAndConditions() {
    return (
        <div className='specificService'>
            <div className="container my-5 pt-5">
                <h1 className="text-center fw-bold mb-4">Terms and Conditions for GDDC Kenya</h1>
                <p className="text-center lead">Last updated: October 25, 2024</p>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <p>
                            Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using the GDDC Kenya website.
                        </p>
                        <p>
                            Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.
                        </p>

                        <h3 className="fw-bold mt-5">Links To Other Websites</h3>
                        <p>
                            Our Service may contain links to third-party websites or services that are not owned or controlled by GDDC Kenya. GDDC Kenya has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services.
                        </p>
                        <p>
                            You further acknowledge and agree that GDDC Kenya shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.
                        </p>

                        <h3 className="fw-bold mt-5">Termination</h3>
                        <p>
                            We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
                        </p>
                        <p>
                            All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
                        </p>

                        <h3 className="fw-bold mt-5">Changes</h3>
                        <p>
                            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                        </p>

                        <h3 className="fw-bold mt-5">Contact Us</h3>
                        <p>
                            If you have any questions about these Terms, please contact us.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
