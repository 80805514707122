import React from 'react';
import { Link } from 'react-router-dom';
import "./css/Services.css";

const PolicyDevelopment = () => {
    return (
        <div className='specificService'>
            <div className="container py-5">
                <h2 className="mb-4 display-6 fw-medium text-center">Policy Development</h2>
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>Overview</h5>
                        <p style={{ fontSize: "1.1rem" }}>
                            As a thought leader in Gender and Disability Mainstreaming, GDDC is committed to fostering a more inclusive society through effective policy development. We host bi-annual policy analysis and development conferences and seminars aimed at equipping relevant decision-makers, stakeholders, and government managers with the knowledge and tools necessary for implementing effective policies.
                        </p>
                    </div>

                    <div className="col-md-12 mb-4">
                        <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>Key Initiatives</h5>
                        <ul style={{ fontSize: "1.1rem" }}>
                            <li>
                                <strong>Bi-Annual Conferences:</strong> These events gather experts, policymakers, and advocates to discuss the latest trends and best practices in gender and disability policy. They serve as a platform for sharing innovative ideas and collaborative strategies.
                            </li>
                            <li>
                                <strong>Workshops:</strong> GDDC conducts workshops that focus on developing actionable policy proposals. Participants are encouraged to engage in interactive sessions that facilitate deeper understanding of policy implications.
                            </li>
                            <li>
                                <strong>Research and Reports:</strong> We produce comprehensive research studies that inform policy discussions. These reports detail the current state of gender and disability issues and provide recommendations for effective policy interventions.
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-12 mb-4">
                        <h5 className="fw-bold mb-3" style={{ fontSize: "1.25rem" }}>Expected Outcomes</h5>
                        <p style={{ fontSize: "1.1rem" }}>
                            Through these initiatives, GDDC aims to achieve:
                        </p>
                        <ul style={{ fontSize: "1.1rem" }}>
                            <li>Enhanced understanding of gender and disability issues among policymakers.</li>
                            <li>Development of inclusive policies that reflect the needs of all stakeholders.</li>
                            <li>Strengthened collaboration between government, NGOs, and community organizations.</li>
                            <li>Increased accountability and transparency in policy implementation.</li>
                        </ul>
                    </div>

                    <div className="col-md-12 text-center">
                        <Link to="/services" className="btn btn-secondary">Back to Services</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolicyDevelopment;
