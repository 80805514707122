import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Membership from "../components/MembershipContent";

export default function Home() {
    return (
        <>
            <Navbar />
            <Membership />
            <Footer />
        </>
    );
}