import React from "react";
import "./css/Services.css";

export default function PrivacyPolicy() {
    return (
        <div className='specificService'>
            <div className="container my-5 pt-5">
                <h1 className="text-center fw-bold mb-4">Privacy Policy for GDDC Kenya</h1>
                <p className="text-center lead">Last updated: October 25, 2024</p>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <h3 className="fw-bold mt-5">Definitions:</h3>
                        <p><strong>GDPR:</strong> General Data Protection Regulation Act.</p>
                        <p><strong>Data Controller:</strong> Natural or legal person who determines the purposes and means of processing personal information.</p>
                        <p><strong>Data Processor:</strong> Natural or legal person processing the data on behalf of the Data Controller.</p>
                        <p><strong>Data Subject:</strong> Any living individual using our Service and is the subject of Personal Data.</p>

                        <h3 className="fw-bold mt-5">1. Principles for Processing Personal Data</h3>
                        <p>Our principles for processing personal data include:</p>
                        <ul className="list-unstyled ms-3">
                            <li><strong>Fairness and lawfulness:</strong> All personal data is collected and processed legally and fairly, protecting Data Subjects’ rights.</li>
                            <li><strong>Specific purpose:</strong> Data processing is restricted to defined purposes.</li>
                            <li><strong>Transparency:</strong> Data Subjects are informed about data collection, processing, and usage.</li>
                        </ul>

                        <h3 className="fw-bold mt-5">2. What Personal Data We Collect and Process</h3>
                        <p>We collect various types of personal data, including but not limited to:</p>
                        <ul className="list-unstyled ms-3">
                            <li>Email address</li>
                            <li>First name and last name</li>
                            <li>Phone number</li>
                            <li>Address, State, Province, ZIP/Postal code, City</li>
                        </ul>

                        <h3 className="fw-bold mt-5">3. How We Use the Personal Data</h3>
                        <p>GDDC Kenya uses the collected personal data for:</p>
                        <ul className="list-unstyled ms-3">
                            <li>Providing services</li>
                            <li>Notifying about changes to our services and/or products</li>
                            <li>Providing customer support</li>
                            <li>Improving our services</li>
                            <li>Detecting and addressing technical issues</li>
                        </ul>

                        <h3 className="fw-bold mt-5">4. Legal Basis for Collecting and Processing Personal Data</h3>
                        <p>GDDC Kenya collects and processes personal data under the following bases:</p>
                        <ul className="list-unstyled ms-3">
                            <li>Performance of a contract</li>
                            <li>Consent provided by the Data Subject</li>
                            <li>Legitimate interests pursued by GDDC</li>
                            <li>Legal compliance</li>
                        </ul>

                        <h3 className="fw-bold mt-5">5. Retention of Personal Data</h3>
                        <p>GDDC Kenya retains personal information only as long as necessary, complying with legal obligations, resolving disputes, and enforcing policies.</p>

                        <h3 className="fw-bold mt-5">6. Data Protection Rights</h3>
                        <p>Residents of the EEA have data protection rights, including:</p>
                        <ul className="list-unstyled ms-3">
                            <li>The right to access, update, or delete information</li>
                            <li>The right of rectification</li>
                            <li>The right to object</li>
                            <li>The right of restriction</li>
                            <li>The right to data portability</li>
                            <li>The right to withdraw consent</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
