// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC9d-AUkZM-M4Ao2Aa0E_l1YvjjtvaDElI",
  authDomain: "gddckenya-d5152.firebaseapp.com",
  projectId: "gddckenya-d5152",
  storageBucket: "gddckenya-d5152.firebasestorage.app",
  messagingSenderId: "626063490133",
  appId: "1:626063490133:web:cc5862235457d5f1eca8dc",
  measurementId: "G-26WQXHHQ05",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, addDoc };
