import React from "react";

const ResponseDetails = () => {
  return (
    <div className="container mt-5">
      <h3 className="text-center text-secondary">
        Attendance Confirmation Information and Table Reservation
      </h3>
      <p>
        Kindly Select/tick the sponsorship Package (Package privileges are on
        the previous page) email us a letter of acceptance that includes names
        of Participating nominees with their office positions.
      </p>
      <ul>
        <li>Awards Headline Sponsor (Confirm Availability)</li>
        <li>Venue Sponsor (Confirm Availability)</li>
        <li>Individual Awards Sponsor</li>
        <li>Platinum Sponsorship Prestige Package</li>
        <li>Gold Package</li>
        <li>Silver Sponsor</li>
        <li>
          1 Table of 10 @ 80,000/- only, with 30 tables allocated for this
          year’s awards
        </li>
        <li>
          Discounted Rates for all TVET Institutions @ 6,000/- per participant
        </li>
      </ul>

      <p>Dress: Formal Evening. Vegetarians catered for.</p>

      <p>
        Send to: info@gddckenya.org or physically to, GreyPark heights 3rd Floor
        Office C3, Victoria Court Road D. Court No. 08, or at the Bazaar
        Building Mezzanine floor, Moi University town campus reception to George
        0720361707.
      </p>

      <p>Phone confirmations: 020-7856896, and 0722451844</p>

      <p>
        Email this response form to info@gddckenya.org and cc to
        richardsimiyu@gmail.com.
      </p>

      <p className="text-center fw-bold fst-italic my-5 text-secondary">
        This is a prestigious, competitive event; we recommend that you return
        this form and payments ASAP as we shall allocate cards and email awards
        online link strictly on a first-come basis.
      </p>
    </div>
  );
};

export default ResponseDetails;
