import { Link } from 'react-router-dom';
import './css/CallToAction.css';

export default function CallToAction() {
    return (
      <div>
        <div className="first-call-to-action d-flex align-items-center justify-content-center text-center py-5">
          <div className="container position-relative">
            <p className="h4 text-white mb-4">
              Ready to Drive Inclusive Change? <br /> <br />
              Join GDDC Kenya in championing gender and disability rights across
              the nation. Together, we can create a more equitable and inclusive
              society for all.
            </p>
            <Link to="/membership">
              <button className="btn btn-secondary text-light btn-lg">
                Get Involved
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
}
