import Navbar from "../components/Navbar";
import OurImpact from "../components/OurImpact";
import CoreValues from "../components/CoreValues";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import ResearchContent from "../components/ResearchPublications"

function ResearchPublications() {
    return (
        <>
            <Navbar />
            <ResearchContent />
            <OurImpact />
            <CoreValues />
            <CallToAction />
            <Footer />
        </>
    );
}

export default ResearchPublications;