import "./css/style.css";

const EqualityInclusionAwards = () => {
  return (
    <div className="inclusionawards">
      <div className="container my-5">
        <div className="card">
          <div className="card-header text-center">
            <h3>The 9th National Equality and Inclusion Awards 2024</h3>
            <p className="mb-0">20th December 2024</p>
            <p className="mb-0">Venue: Emara Ole-Sereni, Nairobi</p>
          </div>
          <div className="card-body">
            <p className="card-text">
              Since the Year 2012, diversity and inclusion ceremony and awards
              have emerged to be the sole reference point of best practices for
              mainstreaming gender and disability in development practices in
              Kenya. The awards have been Africa’s case study, emulated and
              perfected by countries such as South Africa, Malawi, Nigeria,
              Uganda and Rwanda.
            </p>
            <p className="card-text">
              It has been proven as the best linkage practice to be adopted by
              governments and its public and private institutions in ensuring
              that best mainstreaming practices are rewarded and that other
              institutions and nations find opportunities to network, share
              information and indeed follow up to improve on their equality,
              diversity and inclusion programs. County Governments derive from
              this awards platform a peer review mechanism on equality and
              inclusion of each other.
            </p>
            <p className="card-text">
              Some of the past award winners include: Kenyatta University (in
              both gender and disability mainstreaming categories); Kenyatta
              International Convention Centre-KICC( for exemplary Accessibility
              at the work place); National Hospital Insurance Fund (Gender
              Mainstreaming, and social accountability responsibilities; Kenya
              Airports Authority (GBV and Sexual Harassments Management best
              practice); Kiambu County, Kenya Re- Insurance Corporation Ltd (
              Best CSR- Niko Fiti Initiative), Kisii County, Safaricom Limited
              among other agencies.
            </p>
            <p className="card-text">
              The 2024 Equality and Inclusion Awards commissioners have expanded
              its spectrum by adopting the Global Disability Summit Commitments
              by Kenya. We are now going to award Institutions and Individuals
              that have tirelessly worked to ensure achievements towards;
              Inclusive Education, Inclusive Health, Excellent Legislative
              Framework, Inclusive Data, Technology and Innovation, and Economic
              Empowerment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EqualityInclusionAwards;
